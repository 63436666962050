import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src="/ben.gif" className="App-logo" alt="logo" />
        <p>BEN LEE</p>
        <div>
          <a
            className="App-link"
            href="https://www.github.com/mrbenbot"
            target="_blank"
            rel="noopener noreferrer"
          >
            GitHub
          </a>
          {" / "}
          <a
            className="App-link"
            href="https://www.linkedin.com/in/mrbenbot/"
            target="_blank"
            rel="noopener noreferrer"
          >
            LinkedIn
          </a>
        </div>
      </header>
    </div>
  );
}

export default App;
